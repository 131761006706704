body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  width: 1200px;
  margin: auto;
  border: 0px solid orange;
  text-align: center;
}

.tablecontainer {
  margin: auto;
  width: 1200px;
  border: 0px solid orange;
  text-align: center;
}

.tableformat1 {
  margin-left: 30px;
}

.tableformat1 td {
  padding: 2px 15px;
  text-align: left;
}

.grid {
  margin: 0 auto;
  color:#c5c5c5;
}

.grid td {
  width: 25px;
  height: 25px;
  padding: 2px 2px;
  border: 0px solid orange;
  text-align: center;
  float: left;
  margin: 5px;
  font-size: 14px;
  border-radius: 2px;
}

.grid td:hover {
  opacity: .7;
  cursor: pointer;
}

.show {
  padding: 2px 10px;
  border: 1px solid rgb(17, 31, 44);
  background-color: #0ebad1e8;
  text-align: center;
  float: left;
  margin: 5px;
  font-size: 14px;
  border-radius: 2px;
}

.show:hover {
  background-color: orange;
  cursor: pointer;
}

.reserved {
  background: orange;
  color: #fff;
  display: inline-block;
}

.reserved2 {
  background: #001e55;
  color: #fff;
  display: inline-block;
}

.reserved::after {
  background: red;
}

.tempreserved {
  background: rgb(194, 3, 51);
  color: #fff;
  display: inline-block;
}

.playbutt {
  font-size: 1.5em;
}

.available::after {
  background: rgba(53, 209, 14, 0.911);
}

.available {
  background: #0ebad1e8;
  color: #333;
}

.covid {
  background: #6b1101;
  color: #333;
}

.ns {
  background: #2f3130;
  color: #2f3130;
}

.unk {
  background: #b2c0c2e8;
  color: #333;
}

.sold {
  background: #000000;
  color: #fff;
}

.stage {
  width: 1170px;
  margin: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #e0d99d;
  text-align: center;
  margin: 5px;
  font-size: 50px;
  font-weight: bold;
  border-radius: 5px;
  color: #5f5f4a;
  border: 0px solid orange;
  display: inline-block;
}

.clearall {
  clear: both;
}

li {
  list-style: none;
}